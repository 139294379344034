import React, { FunctionComponent, useEffect } from 'react';

import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';

import { ColorSchemeName } from 'react-native';

//import NotFoundScreen from '../screens/NotFoundScreen';


import { LinkingConfiguration, MainDrawerParams, ContactsStackParams } from './routeDefinations';

import MyContacts from '../components/contacts';
import CurrentChats from '../components/chatItems';

import LoginScreen from '../components/login/loginScreen';

import { ThunkDispatch } from 'redux-thunk';


import { Text, Content, Card, CardItem, View, Button, Header, Container } from 'native-base';

import { connect, ConnectedProps } from 'react-redux';
import ensureLogin, { ILoginState } from '../components/login/reducer';

import RegisterSignalR from '../components/notify/registrationHolder';

import { DrawerContentScrollView, DrawerItemList, DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer';

import { IAsyncResult } from '../components/bootCommon/asyncStateMiddleware';

import { LoginResponceModel } from '../generated/LoginResponceModel';

import ContactCard from '../components/contacts/userInfoCompact';

function CustomDrawerContent(
    props: DrawerContentComponentProps<DrawerContentOptions> & { 
        dispatch: ThunkDispatch<{}, {}, any> ,
        currentUserAsync: IAsyncResult<LoginResponceModel>;
    }) {

    const { navigation, currentUserAsync, dispatch } = props;

    return (
        <DrawerContentScrollView {...props}>
            <Container>
                {/*<Header />*/}
                <Content padder>
                    <Card>

                        <CardItem header >
                            <Text>Wellness Backpack</Text>
                            {currentUserAsync && currentUserAsync.result && 
                                currentUserAsync.result.contactCard && 
                                <ContactCard {...currentUserAsync.result.contactCard} />
                            }
                        </CardItem>

                        <CardItem button
                            onPress={() => navigation.navigate('contactsList')}
                        >
                            <Text>My contact list</Text>
                        </CardItem>

                        <CardItem button
                            onPress={() => {
                                navigation.navigate('contactsList');
                                //give it time to navigate b4 logout
                                setTimeout(()=>dispatch(ensureLogin().logOff()),100);
                                
                            }}
                        >
                            <Text>Sign out</Text>
                        </CardItem>


                    </Card>
                </Content>
            </Container>
            {/*<DrawerItemList {...props} />*/}
        </DrawerContentScrollView>
    );
}


const MainDrawer = createDrawerNavigator<MainDrawerParams>();


const ContactsStack = createStackNavigator<ContactsStackParams>();

function ChatNav() {
    return <ContactsStack.Navigator screenOptions={{ headerShown: false }} initialRouteName="contactsList">
        <ContactsStack.Screen name="contactsList" component={MyContacts} />
        <ContactsStack.Screen name="contactsChat" component={CurrentChats} />
    </ContactsStack.Navigator>;
}



export type DisplayContextProps = {
    textColor: string;
};

export const DisplayContext = React.createContext<DisplayContextProps>({
    textColor: 'black',
});


//theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
//theme={DefaultTheme}


type PropsNeeded = ILoginState & {

};

const connector = connect<PropsNeeded, {}, {}>((state) => {
    return ensureLogin().getCurrentState(state);
});

const _RootNavigator: FunctionComponent<ConnectedProps<typeof connector>> = 
({ currentUserAsync, dispatch: dispatchT }) => {

    const dispatch = dispatchT as ThunkDispatch<{}, {}, any>;

    useEffect(() => {
        async function registerMe() {

            const calledUrl = window && window.location && window.location.search;
            if(!calledUrl){
                dispatch && dispatch(ensureLogin().logOff());
            }
            
        }

        registerMe();
        
    }, []);

    if (!(currentUserAsync && currentUserAsync.result)) {
        return <LoginScreen />;
    }

    

    return (
        <NavigationContainer
            linking={LinkingConfiguration} fallback={<Text>Loading...</Text>}

        >
            <RegisterSignalR />
            <DisplayContext.Provider value={{
                textColor: 'black',
            }}>
                <MainDrawer.Navigator 
                    initialRouteName="contacts" 
                    drawerContent={(props) => <CustomDrawerContent {...props} 
                        dispatch={dispatch} 
                        currentUserAsync = {currentUserAsync}
                    />} >
                    <MainDrawer.Screen name="contacts" component={ChatNav} />
                </MainDrawer.Navigator>
            </DisplayContext.Provider>
        </NavigationContainer>
    );

}

export default connector(_RootNavigator);

