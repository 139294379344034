import ReducerBase from '../bootCommon/baseReducer';
import { handleAction, handleActions } from 'redux-actions';

import { IAsyncResult, fetchJsonAsync } from '../bootCommon/asyncStateMiddleware';

import { LoginResponceModel } from '../../generated/LoginResponceModel';
import { AuthenticationCredsModel } from '../../generated/AuthenticationCredsModel';

import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import AsyncStorage from '@react-native-community/async-storage';

type ThunkResult<R> = ThunkAction<R, any, undefined, any>;

import * as _ from 'lodash';

import { URLBase } from '../../siteconfig';

import fetchIntercept from 'fetch-intercept';


export interface ILoginState {
    readonly currentUserAsync: IAsyncResult<LoginResponceModel>;
    readonly creds: AuthenticationCredsModel;

    //if true we are going staright for ONE chat
    readonly directToOneChat:boolean;
};

type CredsProps = { key?: keyof AuthenticationCredsModel, value?: any };

type myActions = {
    setCurrentUser: (data?: PromiseLike<LoginResponceModel>) => PromiseLike<LoginResponceModel>;
    setCreds: (key?: keyof AuthenticationCredsModel, value?: any) => CredsProps;

    setDirectToOneChat: (value:boolean) => boolean;
}

const _login_storge_key = 'login_key';

class loginReducer extends ReducerBase<ILoginState, myActions>{
    createActionList() {
        return {
            setCurrentUser: [
                (data?: PromiseLike<LoginResponceModel>) => data,
                (data?: PromiseLike<LoginResponceModel>) => ({ Async: !!data }),
            ],
            setCreds: (key?: keyof AuthenticationCredsModel, value?: any) => ({ key, value }),
            setDirectToOneChat: (value:boolean) => value,

        };
    }

    reducers() {

        return {

            directToOneChat :handleAction<boolean, boolean>(this._myActions.setDirectToOneChat.toString(),
            (state, action) =>action.payload, false),

            currentUserAsync: handleAction<IAsyncResult<LoginResponceModel>, IAsyncResult<LoginResponceModel>>(this._myActions.setCurrentUser.toString(),
                (state, action) => {

                    const newState = action.payload;
                    return newState ? newState : (null as any);

                }, null as any),

            creds: handleAction<AuthenticationCredsModel, CredsProps>(this._myActions.setCreds.toString(), (state, action) => {
                const newState = _.clone(state || {});


                if (!(action.payload && action.payload.key))
                    return null as any;

                newState[action.payload.key] = action.payload.value;

                return newState;

            }, null as any),
        };
    }

    setCurrentUserActionDefination() {
        return this._myActions.setCurrentUser.toString();
    }

    setCreds = (key?: keyof AuthenticationCredsModel, value?: any) => this._myActions.setCreds(key, value);

    private saveUserInfo(infoPromise?: PromiseLike<LoginResponceModel>) {


        if (infoPromise) {
            return Promise.resolve().then(async () => {
                try {
                    const info = await infoPromise;
                    await AsyncStorage.setItem(_login_storge_key, JSON.stringify(info));
                    return info;

                } catch (err) {
                    AsyncStorage.removeItem(_login_storge_key);
                    throw err;
                }

            });

        } else {
            AsyncStorage.removeItem(_login_storge_key);
            return undefined;
        }


    }

    logOff(): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {
            dispatch(this._myActions.setCurrentUser(this.saveUserInfo()));
        };
    }

    logIn(): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {
            const { creds } = this.getCurrentState(getState());

            if (!creds || !creds.loginname || !creds.password) {
                console.error('creds is empty');
                return;
            }

            const loginPromise = fetchJsonAsync<LoginResponceModel>(fetch(`${URLBase}/api/login`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(creds)
            }));

            dispatch(this._myActions.setCurrentUser(this.saveUserInfo(loginPromise)));

            dispatch(this._myActions.setCreds('password'));
        }
    }

    private upddateFromInvoker(): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {

            
            const calledUrl = window && window.location && window.location.search;

            if(!calledUrl){

                dispatch(this.logOff());

                return ;
            }
                

            const unescaped = decodeURIComponent(calledUrl);

            console.log(`called with : ${unescaped}`);

            //"?smartsms=http://localhost/smarttext.js?divid=twilioDisplay&schoolCode=ABC&contactID=1&token=76A976EE-A047-7645-13EA0A8AD63227D8&beacon=&wifi=&latitude=&longitude=&device=web&IPAddress=71.226.195.242"

            const theParams:any = new URLSearchParams(unescaped);
            const paramsObj = Object.fromEntries(theParams);

            /*
            IPAddress: "71.226.195.242"
            beacon: ""
            contactID: "1"
            recipientID: "152"
            device: "web"
            latitude: ""
            longitude: ""
            schoolCode: "ABC"
            smartsms: "http://localhost/smarttext.js?divid=twilioDisplay"
            token: "76A976EE-A047-7645-13EA0A8AD63227D8"
            wifi: ""
            */

            const creds:AuthenticationCredsModel = {
                loginname:'',
                password:'',
                externalAuth:paramsObj
            };

            

            const loginPromise = async ()=>{
                var logdata = await fetchJsonAsync<LoginResponceModel>(fetch(`${URLBase}/api/login`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(creds)
                }));
               
                return logdata;
            }

            

            dispatch(this._myActions.setCurrentUser(this.saveUserInfo(loginPromise())));

            

        };
    }

    private _fetchIntUnregister?: () => void = undefined;
    private _refreshIfNeededPromise?: Promise<any> = undefined;

    registerFetchHook(): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {

            if (this._fetchIntUnregister) {
                console.log("fetchintercept is already registered");
                return ;
            }

            const recepient:string = (await dispatch(this.upddateFromInvoker())) as any;

            /*
            const existinguser = await AsyncStorage.getItem(_login_storge_key);
            if (existinguser) {
                dispatch(this._myActions.setCurrentUser(Promise.resolve(JSON.parse(existinguser))));
            }
            */

            const me = this;

            this._fetchIntUnregister = fetchIntercept.register({
                request: function (url, initialConfig) {

                    return Promise.resolve(initialConfig)
                        .then(config => {

                            if (!!(config || {}).noAuth) {
                                return config;
                            }

                            const { currentUserAsync } = me.getCurrentState(getState());

                            if (!(currentUserAsync && currentUserAsync.result)) {
                                return config;
                            }

                            if (!me._refreshIfNeededPromise) {
                                //if this is not null, we already have refreshed this
                                me._refreshIfNeededPromise = Promise.resolve();

                                /* we are having a hard time with silent refresh will figure this out later.

                                if (typeof JWTDecoded.exp !== 'undefined' && JWTDecoded.exp < (Date.now().valueOf() / 1000)){
                                    
                            
                                    me._refreshIfNeededPromise = Promise.resolve()
                                            .then(()=>new Promise(r=>setTimeout(r,100)))
                                            .then(()=>dispatch(me.extendSession()))
                                            .then(()=>new Promise(r=>setTimeout(r,100)));
                                }
                                */

                            }

                            return me._refreshIfNeededPromise
                                .then(() => {

                                    const { currentUserAsync } = me.getCurrentState(getState());

                                    if (!(currentUserAsync && currentUserAsync.result))
                                        return config;

                                    config = config || {};
                                    config.headers = config.headers || {};
                                    config.headers['Authorization'] = 'Bearer ' + currentUserAsync.result.jwt;

                                    //config.headers=_.assign( config.headers,  me.getAdditionalHeaders(getState));

                                    me._refreshIfNeededPromise = undefined;
                                    return config;
                                })
                                .catch(err => {

                                    me._refreshIfNeededPromise = undefined;
                                    throw err;

                                });
                        })

                        .then(config => Promise.resolve([url, config]));
                },

                response: function (response) {
                    if (401 == response.status) {
                        const { currentUserAsync } = me.getCurrentState(getState());

                        if (currentUserAsync && currentUserAsync.result) {
                            dispatch(me._myActions.setCurrentUser());
                        }
                    }
                    return response;
                },


            });

           
        }


    };
}


export default () => loginReducer.getInstance(loginReducer, 'login'); 