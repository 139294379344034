import React, { FunctionComponent, useEffect, useState } from 'react';

import { DrawerScreenProps } from '@react-navigation/drawer';
import { ContactsStackParams } from '../../navigation/routeDefinations';
import { MyDrawerContainer } from '../../screens/DrScreenBase';
import { ThunkDispatch } from 'redux-thunk';

import ContactsView from './contactsList';

import { StyleSheet } from 'react-native';
import { connect, ConnectedProps } from 'react-redux';

import { View, Input, Icon, Button, H2, Text } from 'native-base';
import ensureLogin from '../login/reducer';
import ensureContacts from './reducer';

type RoutedParams = DrawerScreenProps<ContactsStackParams, 'contactsList'>;

type PropsNeeded = {
    readonly searchPhrase: string;
};

const connector = connect<PropsNeeded, {}, {}>((state, myp) => {
    const { searchPhrase } = ensureContacts().getCurrentState(state);
    return {
        searchPhrase
    };
});

const _SearchBar: FunctionComponent<ConnectedProps<typeof connector>> = ({ searchPhrase, dispatch: dispatchT }) => {
    const dispatch = dispatchT as ThunkDispatch<{}, {}, any>;

    return <View style={styles.searchBar}>
        {/*<H2 style={styles.whiteColor}>Contacts</H2>*/}
        <Icon type="FontAwesome" name='search' style={styles.whiteColor} />
        <Input placeholder='contacts'
            onChangeText={text => dispatch(ensureContacts().updateSearch(text))}
            value={searchPhrase || ''}
            style={styles.findBox}
        />
    </View>;

}

const SearchBar = connector(_SearchBar);

type ContactsPropsNeeded = {
    readonly message?:string;
}

const contactConnector = connect<ContactsPropsNeeded, {}, RoutedParams>((state, ownProps) => {

    const {currentUserAsync} = ensureLogin().getCurrentState(state);
    return {
        message: currentUserAsync && currentUserAsync.result && currentUserAsync.result.message
    };
});


 function MyContacts({ navigation, message }
    : RoutedParams & ConnectedProps<typeof contactConnector>) {

    const [hideMessage,setHideMessage] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
            setHideMessage(true);
        },1000*10)
    },[message]);

    return <MyDrawerContainer
        navigation={navigation}

        TitleComponent={<SearchBar />}

    >
        {message && !hideMessage && <View style={styles.card}>
            <Text style={styles.message}>{message}</Text>
        </View>
        }

        <ContactsView navigation={navigation} />
    </MyDrawerContainer>;
}

export default contactConnector(MyContacts);

const styles = StyleSheet.create({
    searchBar: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,

    },
    whiteColor: {
        color: 'white',
        marginRight: 10
    },
    findBox: {
        backgroundColor: 'white',
        height: 34,
        flex: 1,
        
    },
    message:{
        color:'#808080'
    },
    card: {
        flexDirection: 'row',
        alignItems:'center',
        justifyContent:'center'
    }
});

