import React, { useContext } from 'react';
import ChatView from './chatBox';

import { MyDrawerContainer } from '../../screens/DrScreenBase';

import { DrawerScreenProps } from '@react-navigation/drawer';
import { ContactsStackParams } from '../../navigation/routeDefinations';

import { connect, ConnectedProps } from 'react-redux';
import ensureChatItems, { IChatState } from './reducer';
import ensureContacts from '../contacts/reducer';
import { ThunkDispatch } from 'redux-thunk';

import { MyContactModel } from '../../generated/MyContactModel';

import ContactSummery from '../contacts/userInfoCompact';
import { StyleSheet, ActivityIndicator } from 'react-native';

import ensureLogin from '../login/reducer';





type RoutedParams = DrawerScreenProps<ContactsStackParams, 'contactsChat'>;

import { View } from 'native-base';

import * as _ from 'lodash';

import {DisplayContext} from '../../navigation';

import NewMessage from './newMsg';

type PropsNeeded = {
    readonly selectedContact: MyContactModel;
    readonly refreshing:boolean;
    readonly hideBack:boolean;
    readonly message?:string;
}

const connector = connect<PropsNeeded, {}, RoutedParams>((state, ownProps) => {
    const { selectedContact } = ensureContacts().getCurrentState(state);
    const {allChatItemsAsync} = ensureChatItems().getCurrentState(state);

    const refreshing = !! (allChatItemsAsync && allChatItemsAsync.isLoading);

    const {currentUserAsync} = ensureLogin().getCurrentState(state);

    const directSelectedRecepientId = currentUserAsync && currentUserAsync.result && currentUserAsync.result.selectedRecepientId;

    return {
        selectedContact,
        refreshing,
        hideBack : !!directSelectedRecepientId,
        message: currentUserAsync && currentUserAsync.result && currentUserAsync.result.message
    };
});


function CurrentChats({ navigation, route, selectedContact, refreshing, hideBack, message, dispatch: dispatchT }
    : RoutedParams & ConnectedProps<typeof connector>) {

    const dispatch = dispatchT as ThunkDispatch<{}, {}, any>;
    const userId = route && route.params && route.params.userId;

    const displayContext = useContext(DisplayContext);

    

    return <MyDrawerContainer
        navigation={navigation}
        hideBack={hideBack}
        TitleComponent={<View style={styles.title}>
            <DisplayContext.Provider value={ _.assign({},displayContext,{
                textColor: 'white',
            })}>
                <ContactSummery {...selectedContact} />
            </DisplayContext.Provider>
            {refreshing && <ActivityIndicator size="small" color="#ffffff" />}
        </View>
        }
    >
        <View style={styles.container}>
            {!selectedContact?.noReply && <NewMessage recepientId={userId} /> }
            <ChatView recepientId={userId} />
        </View>
    </MyDrawerContainer>;
}

export default connector(CurrentChats);

const styles = StyleSheet.create({
    container: {

        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },

    title: {
        flexDirection: 'row',
    }
});
