import Constants from 'expo-constants';
import {Platform} from 'react-native';

//let _URLBase = 'http://localhost:65011';

let _URLBase ='';

let _useExpoNotification = false;

if(Platform.OS === 'web'){

}else{
    _useExpoNotification = true;
}

if(Constants.manifest && Constants.manifest.extra){
    if(Constants.manifest.extra.APIServer){
        _URLBase = Constants.manifest.extra.APIServer;
    }

    /*
    const j = Constants;
    const h = JSON.stringify(Constants);

    var k = Platform.OS;

    console.log(h);
    
    */

    if(Constants.manifest.extra.webAPIServer && Constants.platform  && Platform.OS === 'web'){
       // _URLBase = Constants.manifest.extra.webAPIServer;
    }

}

if (Constants.manifest.releaseChannel && Constants.manifest.releaseChannel.indexOf('wellness-staging') !== -1) {
    _URLBase = 'https://wellness-staging.labizbille.com'
}


export const URLBase = _URLBase;