import React, { FunctionComponent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { StyleSheet } from 'react-native';

import ensureContacts, { IContactsState } from './reducer';

import { FlatList } from 'react-native';

import { Text, Item, Input, Icon, View, Button } from 'native-base';

import { Image, ActivityIndicator } from 'react-native';

import { MyContactModel } from '../../generated/MyContactModel';

import { DrawerNavigationProp } from '@react-navigation/drawer';

import { ContactsStackParams } from '../../navigation/routeDefinations';

import ContactDisplay from './contactDisplay';

import ensureLogin from '../login/reducer';



type ComponentProps = {
    navigation: DrawerNavigationProp<ContactsStackParams, 'contactsList'>;
}


import * as _ from 'lodash';
import { IAsyncResult } from '../bootCommon/asyncStateMiddleware';

type PropsNeeded = IContactsState & {
    selectedRecepientId?: string;
};

const connector = connect<PropsNeeded, {}, ComponentProps>((state, myp) => {
    const  ret = ensureContacts().getCurrentState(state);

    const {currentUserAsync} = ensureLogin().getCurrentState(state);

    const selectedRecepientId = currentUserAsync && currentUserAsync.result && currentUserAsync.result.selectedRecepientId;

    return _.assign({},ret,{selectedRecepientId});

});

const ContactsList: FunctionComponent<ConnectedProps<typeof connector> & ComponentProps> = 
    ({ navigation, dispatch: dispatchT, contactsAsync, selectedContact, searchPhrase, selectedRecepientId }) => {

    const dispatch = dispatchT as ThunkDispatch<{}, {}, any>;

    useEffect(() => {

        async function loadme() {
            const  {payload} = (await dispatch(ensureContacts().loadContacts())) as any;

            await new Promise(r => setTimeout(r,100));

            const newcontactsAsync:IAsyncResult<MyContactModel[]> = payload;

                      

            if(!!selectedRecepientId){
                
                const selected = newcontactsAsync && newcontactsAsync.result && _.find(newcontactsAsync.result,c=>c.userId == selectedRecepientId);
                if(!selected){
                    console.error(`selectedid ${selectedRecepientId} not found in contacts list`);
                    return;
                }
                
                
                dispatch(ensureContacts().selectContact(selected));
                navigation.navigate("contactsChat", { userId: selected.userId });
                
            }
            
        }


        loadme();

        

    }, []);

    if (contactsAsync && contactsAsync.isLoading) {
        return <View style={styles.allmiddle}>
            <ActivityIndicator size="large" color="#00ff00" />
            <Text>loading contacts</Text>
        </View>;
    } else if (contactsAsync && contactsAsync.error) {
        return <Text style={{ color: 'red' }}>
            {contactsAsync.error.Message || 'failed to load contacts '}
        </Text>;
    }

    if(contactsAsync && contactsAsync.result && 0 == contactsAsync.result.length ){
        return <View style={styles.allmiddle}>
            <Text>No contacts found ...</Text>
        </View>
    }

    let contacts = contactsAsync && contactsAsync.result;

    if(contacts && searchPhrase){
        contacts = contacts.filter(c=>c.displayName 
            && c.displayName.toLowerCase() .includes(searchPhrase.toLowerCase()))
    }

    return <View style={styles.container}>
        
        {contacts && <FlatList<MyContactModel>
            data={contacts}
            keyExtractor={i => i.userId}
            renderItem={i => <View
                style={selectedContact && selectedContact.displayName == i.item.userId ? _.assign({}, styles.cardHolder, { backgroundColor: '#ccc' }) : styles.cardHolder}>
                <Button style={styles.card} transparent info={selectedContact && selectedContact.displayName == i.item.userId}
                    onPress={() => {

                        dispatch(ensureContacts().updateSearch());
                        dispatch(ensureContacts().selectContact(i.item));

                        navigation.navigate("contactsChat", { userId: i.item.userId });
                    }}
                >
                    <ContactDisplay  contact ={i.item} {...{dispatch}} />
                </Button>
            </View>
            }

        />}
    </View>;
}


export default connector(ContactsList);



const styles = StyleSheet.create({
    allmiddle:{
        flex:1,
        justifyContent:'center',
        alignItems:'center'
    },
    container: {
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',

    },
    card: {
        borderColor: '#ccc',
        borderWidth: 2,
        borderRadius: 30,
        flex: 1,

        alignSelf: 'auto'

    },
    cardHolder: {
        marginLeft: 60,
        marginRight: 20,
        marginHorizontal: 20,
        marginVertical: 10,
        flex: 1,

    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 10,
        height: 1,

        //margin: 10,
        backgroundColor: 'rgba(0,0,0,0.1)'
    },
    item: {
        padding: 20,
        marginVertical: 8,
        marginHorizontal: 16,
    },
});




