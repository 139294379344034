import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import ensureChatItems, { MessageToSendProps } from './reducer';
import {  Item, Input, Icon, Button } from 'native-base';

//const  AutoGrowingTextInput:any = require('react-native-autogrow-textinput');

type PropsNeeded = {
    readonly newMessage: MessageToSendProps;
}

type ComponentProps = {
    recepientId: string;
};


const connector = connect<PropsNeeded, {}, ComponentProps>((state, ownProps) => {
    const { newMessage } = ensureChatItems().getCurrentState(state);

    return {
        newMessage
    };
});

const newMsgView: FunctionComponent<ConnectedProps<typeof connector> & ComponentProps> =
    ({ newMessage, recepientId, dispatch: dispatchT }) => {

        const dispatch = dispatchT as ThunkDispatch<{}, {}, any>;
        

        return <Item rounded  >
            <Input placeholder='Type your message here'
                onChangeText={text => dispatch(ensureChatItems().updateNewMessage(text))}
                
                value={(newMessage && newMessage.data && newMessage.data.text) || ''}
            />
            <Button transparent
                disabled={!(newMessage && newMessage.data && newMessage.data.text)}
                onPress={() => dispatch(ensureChatItems().sendMessage(recepientId))}
            >
                <Icon type="FontAwesome" name='send-o' />
            </Button>
        </Item>;
    }

export default connector(newMsgView);



