import ReducerBase from '../bootCommon/baseReducer';
import { handleAction, handleActions, Action } from 'redux-actions';

import { IAsyncResult, fetchJsonAsync } from '../bootCommon/asyncStateMiddleware';

import { MyContactModel } from '../../generated/MyContactModel';

import { ThunkAction, ThunkDispatch } from 'redux-thunk';


type ThunkResult<R> = ThunkAction<R, any, undefined, any>;

import * as _ from 'lodash';

import { URLBase } from '../../siteconfig';

import ensureLogin from '../login/reducer';

import { deboucedLoader } from '../utils';
import { ActionSheet } from 'native-base';

export interface IContactsState {
    readonly contactsAsync: IAsyncResult<MyContactModel[]>;
    readonly selectedContact: MyContactModel;
    readonly messageCounts:{[contactId:string]:number};

    readonly searchPhrase:string;
};

type myActions = {
    loadContacts: () => PromiseLike<MyContactModel[]>;
    selectContact: (value?:MyContactModel) => MyContactModel;

    updateMessageCounts: (value:{[contactId:string]:number})=> {[contactId:string]:number};

    updateSearch: (value?:string)=>string;
}

const _login_storge_key = 'login_key';

class contactsReducer extends ReducerBase<IContactsState, myActions>{
    createActionList() {
        return {
            loadContacts: [
                () => fetchJsonAsync<MyContactModel[]>(
                    fetch(`${URLBase}/api/contacts`)
                ),
                () => ({ Async: true }),
            ],
            selectContact: (value?:MyContactModel) => value,

            updateSearch: (value?:string) => value,

            updateMessageCounts: (value:{[contactId:string]:number})=> value,

        };
    }

    reducers() {

        const onLogOff =(
            state: IAsyncResult<any>, action: Action<any>) => {
                if(!action.payload){
                    //if we just signed out remove all messages
                    return null;
                }
                    
                return state;
            };

        const contactsHandlers: any = {};

        contactsHandlers[this._myActions.loadContacts.toString()] = 
                (state:IAsyncResult<MyContactModel[]>, action:Action<IAsyncResult<MyContactModel[]>>) => action.payload;

        contactsHandlers[ensureLogin().setCurrentUserActionDefination()] = onLogOff;

        const selectedContactHandlers: any = {};

        selectedContactHandlers[this._myActions.selectContact.toString()] = 
                (state:MyContactModel, action:Action<MyContactModel>) => action.payload;
        
        selectedContactHandlers[ensureLogin().setCurrentUserActionDefination()] = onLogOff;

        const countHandlers: any = {};
        countHandlers[this._myActions.updateMessageCounts.toString()] = 
                (state:{[contactId:string]:number}, action:Action<{[contactId:string]:number}>) => {

            return _.assign({},state,action.payload);

        };
        
        countHandlers[ensureLogin().setCurrentUserActionDefination()] = onLogOff;

        return {

            contactsAsync: handleActions(contactsHandlers, null),

            selectedContact: handleActions(selectedContactHandlers, null),

            messageCounts:handleActions(countHandlers, {}),

            searchPhrase:handleAction<string,string>(this._myActions.updateSearch.toString(),(state,action)=>action.payload||'','')
        };
    }

    updateSearch = (value?:string) => this._myActions.updateSearch(value);

    loadContacts = () => this._myActions.loadContacts();

    selectContact = (value?:MyContactModel) => this._myActions.selectContact(value);

    readonly msgCountHandler = new deboucedLoader<IContactsState>(async (dispatch, getState,pendingContactIds) => {

        
        if(!pendingContactIds || 0 == pendingContactIds.length){
            return;
        }

        const counts = await fetchJsonAsync<{[contactId:string]:number}>(fetch(`${URLBase}/api/chat/count`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(pendingContactIds)
        }));

        dispatch(this._myActions.updateMessageCounts(counts));

        //also ensure all contacts exists
        const {contactsAsync} = this.getCurrentState(getState());

        //const missingContacts = contactsAsync && contactsAsync.result && contactsAsync.result.filter(c=> !pendingContactIds.includes(c.userId)  );


        const missingContact = contactsAsync && contactsAsync.result && pendingContactIds.find(c=> !contactsAsync.result?.find(ec=>ec.userId == c)  );
        
        
        if(missingContact){
            console.log(`found missing contacts... reloading`);
            dispatch(this.loadContacts());
        }
    });


}


export default () => contactsReducer.getInstance(contactsReducer, 'contacts'); 