import React, { FunctionComponent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';

import ensureNotify from '../notify/reducer';

const connector = connect<{}, {}, {}>((state) => {
    return {};
});

import { View } from 'native-base';

import ensureMessages from '../chatItems/reducer';

import ensureContacts from '../contacts/reducer';

/*
//we need it here so that we donot double susbcribe
function onChatMessage(json :string){
    
    console.log('got message');
*/

const RegisterForSignalR: FunctionComponent<ConnectedProps<typeof connector>>= ({dispatch:dispatchT})=>{

    const dispatch = dispatchT as ThunkDispatch<{}, {}, any>;


    useEffect(() => {
        
        //dispatch(ensureNotify().subscribe('chatMessage',onChatMessage));
        dispatch(ensureNotify().subscribe<{id:string,senderId:string}>('chatMessage',({id,senderId})=>{

            dispatch(ensureMessages().messageReceivedHandler.addToPending(senderId));

            dispatch(ensureContacts().msgCountHandler.addToPending(senderId));

        }));

        return ()=>{
            dispatch(ensureNotify().stopConnection('logged out'));
        };
    }, []);

    return <View/>;


}

export default connector(RegisterForSignalR);