import React, { FunctionComponent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { StyleSheet } from 'react-native';

import ensureChatItems, { IChatState } from './reducer';

import { FlatList } from 'react-native';

import { ChatMessageModel } from '../../generated/ChatMessageModel';

import { IAsyncResult} from '../bootCommon/asyncStateMiddleware';


import { Text, Item, Input, Icon, View, Button } from 'native-base';

import ChatItemView from './itemView';

type ComponentProps = {
    recepientId: string;
};

type PropsNeeded = {
    readonly allChatItemsAsync: IAsyncResult<ChatMessageModel[]>;
}

const connector = connect<PropsNeeded, {}, ComponentProps>((state, ownProps) => {
    const{allChatItemsAsync} =  ensureChatItems().getCurrentState(state);
    return {
        allChatItemsAsync
    };
});


const ChatView: FunctionComponent<ConnectedProps<typeof connector> & ComponentProps>
    = ({ dispatch: dispatchT, recepientId, allChatItemsAsync }) => {

        const dispatch = dispatchT as ThunkDispatch<{}, {}, any>;

        useEffect(() => {
            dispatch(ensureChatItems().loadMessages(recepientId));
        }, [recepientId]);
   

        return (<React.Fragment>

            {
                /*<View style={styles.separator}   />*/
            }


            {allChatItemsAsync && allChatItemsAsync.result && <FlatList<ChatMessageModel>
                data={allChatItemsAsync.result}
                inverted={false}
                keyExtractor={(item) => item.id ?? item.createId}

                renderItem={(item) => <ChatItemView message={item.item} {...{recepientId, dispatch}} />}

                onEndReachedThreshold={0.2}

                onEndReached={() => {
                    console.log("end reached");
                    dispatch(ensureChatItems().loadMessages(recepientId,true));
                }}

            />
            }

            {allChatItemsAsync && allChatItemsAsync.error && <Text style={{ color: 'red' }}>
                {allChatItemsAsync.error.Message || 'failed to load messages '}
            </Text>
            }

        </React.Fragment>
        );
    }

export default connector(ChatView);


const styles = StyleSheet.create({
    
    separator: {
        marginVertical: 10,
        height: 1,

        //margin: 10,
        backgroundColor: 'rgba(0,0,0,0.1)'
    },
    item: {
        padding: 20,
        marginVertical: 8,
        marginHorizontal: 16,
    },
});

