import AsyncLock from 'async-lock';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import * as _ from 'lodash';

type ThunkResult<R, T> = ThunkAction<R, T, undefined, any>;

//returns handled recepiets
type callbackType<T> = (dispatch: ThunkDispatch<T, undefined, any>, getState: () => any,pendingRecepiants: string[])=>PromiseLike<void>;

let _deBouncedId:number =0;

export class deboucedLoader<T>{

    private readonly _pendingLock = new AsyncLock();
    private _pendingIds: string[] = [];

    private readonly _callback : callbackType<T>;
    private readonly _debouceKey:string;

    constructor(callback: callbackType<T>){
        this._callback = callback;
        this._debouceKey = `LOAD_DELAYED_UPDATES_${++_deBouncedId}`;
    }


    private processPendingDebounced() {

        const thunk = async (dispatch: ThunkDispatch<T, undefined, any>, getState: () => any) => {
            console.log("loading message updates");

            const currentPendings = _.clone(this._pendingIds);

            await this._callback(dispatch,getState,currentPendings);

            await this._pendingLock.acquire('_pendingRecepiants', () => {
                this._pendingIds = _.filter(this._pendingIds, r => !_.includes(  currentPendings||[] , r));
            });

        }

        thunk.meta = {
            debounce: {
                time: 1000,
                key: this._debouceKey
            }
        };

        return thunk;
    };


    addToPending(dataId: string): ThunkResult<Promise<void>, T> {

        return async (dispatch, getState) => {

            if(!dataId){
                console.log('addToPending: null argument')
                return;
            }
   

            await this._pendingLock.acquire('_pendingRecepiants', () => {
                this._pendingIds.push(dataId);
            });

            dispatch(this.processPendingDebounced());
        };
    }

}