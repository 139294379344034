import React, { FunctionComponent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { StyleSheet, Platform } from 'react-native';

import ensureLogin, { ILoginState } from './reducer';

import { DrawerNavigationProp } from '@react-navigation/drawer';
import { ContactsStackParams } from '../../navigation/routeDefinations';

import Constants from 'expo-constants';
import { URLBase } from '../../siteconfig';

import {buildVer} from '../../buildVersion';

type PropsNeeded = ILoginState & {

};

import { Image, ActivityIndicator } from 'react-native';

import { Text, Item, Input, Icon, View, Button, Container } from 'native-base';

const connector = connect<PropsNeeded, {}, {}>((state) => {

    const g = ensureLogin().getCurrentState(state);
    return g;
});

const LoginView: FunctionComponent<ConnectedProps<typeof connector>> 
    = ({ dispatch: dispatchT, creds, currentUserAsync}) => {

    const dispatch = dispatchT as ThunkDispatch<{}, {}, any>;

    useEffect(() => {
        async function registerMe() {
            await dispatch(ensureLogin().registerFetchHook());
            
        }

        registerMe();
        
    }, []);

    return <Container>
        <View style={styles.allScreen}>
            <View style={styles.loginHolder}>

                {/*<Image
                    style={styles.logoImage}
                    source={require('./wellnesslogo.png')}
                />*/}

                <View style={styles.login}>
                    {currentUserAsync && currentUserAsync.isLoading ? <React.Fragment>
                        <ActivityIndicator size="large" color="#00ff00" />
                        <Text>Signing in</Text>

                    </React.Fragment> : <React.Fragment>
                            <Item rounded style={styles.loginElements} >
                                <Input placeholder='Username'
                                    onChangeText={text => {

                                        dispatch(ensureLogin().setCreds("loginname", text))
                                    }
                                    }
                                    value={(creds && creds.loginname) || ''}
                                />
                            </Item>
                            <Item rounded style={styles.loginElements}>
                                <Input placeholder='Password' secureTextEntry={true}
                                    onChangeText={text => dispatch(ensureLogin().setCreds("password", text))}
                                    value={(creds && creds.password) || ''}
                                />
                            </Item>

                            {currentUserAsync && currentUserAsync.error && <Text style={{ color: 'red' }}>
                                {currentUserAsync.error.Message || 'failed to sign in '}
                            </Text>
                            }

                            <View style={styles.loginElements}>
                                <Button primary rounded block
                                    disabled={!(creds && creds.password && creds.password)}
                                    onPress={() => dispatch(ensureLogin().logIn())}
                                >
                                    <Icon type="FontAwesome" name='sign-in' />
                                    <Text>Sign in</Text>

                                    {/*<View>
                                    <Text>Platform :{Platform.OS}</Text>
                                    <Text>URLBase :{URLBase}</Text>
                                </View>
                                */}


                                </Button>
                            </View>

                        </React.Fragment>
                    }

                </View>

            </View>

            <View style={styles.footer}>
                <Text style={styles.footerText}>Version :{buildVer}</Text>
            </View>

        </View>

    </Container>;

}

const logoWidth = 300;

const styles = StyleSheet.create({
    loginElements: {
        margin: 10
    },
    login: {
        padding: 20,
        margin: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    logoImage: {
        width: logoWidth,
        height: logoWidth * 135 / 420,
        resizeMode: 'stretch',
    },
    loginHolder: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    allScreen:{
        flex: 1,
        justifyContent:'flex-start',
        alignItems:'stretch'
    },
    footer:{
        paddingEnd:10,
        flexDirection:'row',
        justifyContent:'flex-end'
    },
    footerText:{
        color:'#ccc',
        fontStyle:'italic'
    }
});

export default connector(LoginView);

