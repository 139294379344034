import * as Linking from 'expo-linking';

export const LinkingConfiguration= {
    prefixes: [Linking.makeUrl('/')],
    config: {
        screens: {
            contacts: {
                screens: {
                    contactsChat: 'chat/:userId'
                }
            }

        }
    },

};

export type MainDrawerParams = {
    contacts: undefined;
}

export type ContactsStackParams = {
    contactsList: undefined;
    contactsChat: { userId: string };
};


