import React, { FunctionComponent, useEffect } from 'react';
import ContactSummery from './userInfoCompact';

import { MyContactModel } from '../../generated/MyContactModel';
import { connect, ConnectedProps } from 'react-redux';

import { ThunkDispatch } from 'redux-thunk';

import ensureContacts from './reducer';

import { Badge, View, Text } from 'native-base';

import { StyleSheet } from 'react-native';


type ComponentProps = {
    contact: MyContactModel;
}

type PropsNeeded = {
    count: number;
};

const connector = connect<PropsNeeded, {}, ComponentProps>((state, myp) => {
    const { messageCounts } = ensureContacts().getCurrentState(state);

    const count = myp && messageCounts && myp.contact && messageCounts[myp.contact.userId] || 0;

    return { count };
});


const ContactDisplay: FunctionComponent<ConnectedProps<typeof connector> & ComponentProps>
    = ({ contact, count, dispatch: dispatchT }) => {

        const dispatch = dispatchT as ThunkDispatch<{}, {}, any>;

        
        useEffect(() => {
    
            const ackTimer = setTimeout(()=>{
                dispatch(ensureContacts().msgCountHandler.addToPending(contact && contact.userId));
            },500);
    
            return ()=>{
                //if we have not acked yet then clear the timeout
                clearTimeout(ackTimer);
            };
    
            
        }, [contact && contact.userId]);
        


        return <View style={styles.container}>
            <ContactSummery {...contact} />
            {!!count && <Badge danger style={styles.count}><Text>{count}</Text></Badge>}
        </View>;
    }

export default connector(ContactDisplay);

const styles = StyleSheet.create({
    container:{
        flex:1,
        flexDirection:'row',
        justifyContent:'space-between'
    },
    count:{
        marginEnd:20
    }
});
