import * as _ from 'lodash';

//sets propses promise state values
import { isFSA } from 'flux-standard-action';

function isPromise(val:any) {
    return val && typeof val.then === 'function';
}


function isFunction(val:any) {
    return val && typeof val === 'function';
}

export async function fetchJsonAsync<T>(responsePromise:Promise<Response>){
    const responce = await checkFetchErrorAsync(responsePromise);
    return (await responce.json() ) as T;
}

export type UIError ={
    Message:string;
}

export async function checkFetchErrorAsync(responsePromise:Promise<Response>) {

    const response = await  responsePromise;
    
    if (!response.ok) {
        
        if(!response.headers)
            console.error('checkFetchError called with non http response');

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') != -1){
            const err = await response.json();

            if(!err.Message){
                err.Message = 'unknown error';
            }

            throw err as UIError;
        }
        else{
            throw {Message :response.statusText} as UIError;
        }

    }
    else
        return response;
}

export interface IAsyncResult<T> {
    isLoading?: boolean;
    isLoaded?: boolean;
    error?: UIError;
    result?: T;
}

export const asyncStateResult = (store:any) => (next:any) => (action:any) => {
    
    
    if (!action.meta || !action.meta.Async|| !isFSA(action) ||  isFunction(action.payload)) {
        return next(action);
    }

    
    
    //we are adding the isLoaded param so that spinners can just watch that
    if(((action.payload||{}) as any).isLoading){
        action.payload= {isLoading:true, isLoaded:false} as any;
        
    }else{
        action.payload= (action.error? {error:action.payload}:{result:action.payload}) as any;
        (action.payload as any).isLoaded = true;
    }

    return next(action);
        
}

export function asyncStateCreater({dispatch} : any){
    
    return (next:any) => (action:any) => {
    
        if (!action.meta || !action.meta.Async ||!isPromise(action.payload )) {
            return next(action);
        }
        
        //we are adding the isLoaded param so that spinners can just watch that
        let loadingAction = _.clone(action);
        loadingAction.payload = {isLoading:true,isLoaded:false};
        dispatch(loadingAction);
    
    
        return next(action);
        
    }
}

export function deriveAsync<S, T>(sourceAsync: IAsyncResult<S>, fn: (input: S) => T): IAsyncResult<T> {
    if (!sourceAsync)
        return null as any;

    const ret: IAsyncResult<T> = _.assign({}, sourceAsync, { result: sourceAsync.result && fn(sourceAsync.result) });
    return ret;
}
