import ReducerBase from '../bootCommon/baseReducer';
import { handleAction, handleActions, Action } from 'redux-actions';

export enum ConnectionState { notInitialized, connecting, connected, failed };
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import AsyncLock from 'async-lock';

import ensureLogin from '../login/reducer';

import * as _ from 'lodash';

import { URLBase } from '../../siteconfig';

import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';
import Constants from 'expo-constants';

export interface ISignalRState {
    readonly connectionState: ConnectionState;
    readonly failedError: string;
};

type myActions = {
    setConnectionState: (value: ConnectionState) => ConnectionState;
    setFailedError: (value?: string) => string;
}

type ThunkResult<R> = ThunkAction<R, ISignalRState, undefined, any>;

let _uniqueSubscriberId: number = 0;

class signalRReducer extends ReducerBase<ISignalRState, myActions>{

    createActionList() {
        return {
            setConnectionState: (value: ConnectionState) => value,
            setFailedError: (value?: string) => value ?? null
        };
    }

    reducers() {
        return {
            connectionState: handleAction<ConnectionState, ConnectionState>(this._myActions.setConnectionState.toString(), (state, action) => action.payload, ConnectionState.notInitialized),
            failedError: handleAction<string, string>(this._myActions.setFailedError.toString(), (state, action) => action.payload, 'ready to connect'),
        };
    }

    _connection: any = null;

    _intialRetrtCount = 0;


    private async ensureStarted(dispatch: ThunkDispatch<ISignalRState, undefined, any>) {
        const _mine = this;
        try {
            console.log("signalR Starting");
            await this._connection.start();
            console.log("signalR connected");

            dispatch(_mine._myActions.setConnectionState(ConnectionState.connected));
            dispatch(_mine._myActions.setFailedError(undefined));

            if (_mine._subscribedFun.length > 0) {
                console.log(`subscribing to ${_mine._subscribedFun.length} subscribers`);
                _.each(_mine._subscribedFun, sfun => dispatch(sfun.thunkFn));
            }

        } catch (err) {

            const maxRetry = 10;

            if (_mine._intialRetrtCount++ < maxRetry) {
                const interval = 5 * _mine._intialRetrtCount;
                dispatch(_mine._myActions.setFailedError((err || '').toString() + ` : trying again in ${interval} seconds. Retry count ${_mine._intialRetrtCount}`));
                await new Promise(r => setTimeout(r, 1000 * interval));
                await _mine.ensureStarted(dispatch);
            }
            else {
                dispatch(_mine._myActions.setConnectionState(ConnectionState.failed));
                dispatch(_mine._myActions.setFailedError((err || '').toString() + ` : failed after ${maxRetry} tries`));

                throw err;

            }
        }
    }

    //use to signal that we have stooped the connection on purpose
    private _connectedStopped_reason?: string = undefined;

    private readonly _singRCreareLock = new AsyncLock();


    private async ensureInit(dispatch: ThunkDispatch<ISignalRState, undefined, any>, getState: () => any) {
        await this._singRCreareLock.acquire('initializeSignalR', () => this.ensureInit_unSafe(dispatch, getState));
    }

    private async ensureInit_unSafe(dispatch: ThunkDispatch<ISignalRState, undefined, any>, getState: () => any) {
        const _mine = this;
        if (!!this._connection) {
            console.log("signalR Connection exists checking started");

            const { HubConnectionState } = await import(/*webpackChunkName: "signalr"*/'@microsoft/signalr');

            while (this._connection.state != HubConnectionState.Connected) {
                await new Promise(r => setTimeout(r, 100));
                console.log("signalR Waiting for checking started");
            }

            console.log("signalR Connection exists and started");
            return Promise.resolve();
        }


        console.debug("SignalR -> ensureInit entered");

        dispatch(_mine._myActions.setConnectionState(ConnectionState.connecting));
        dispatch(_mine._myActions.setFailedError('connecting to Messaging server'));

        //Give UI a chance to update
        await new Promise(r => setTimeout(r, 100));

        const { HubConnectionBuilder, LogLevel } = await import(/*webpackChunkName: "signalr"*/'@microsoft/signalr');

        const connection = (new HubConnectionBuilder())
            .withUrl(`${URLBase}/messaging`, {
                accessTokenFactory: () => {
                    const { currentUserAsync } = ensureLogin().getCurrentState(getState());
                    return currentUserAsync && currentUserAsync.result && currentUserAsync.result.jwt || '';
                }
            })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        this._connection = connection;


        connection.onreconnecting((error) => {
            console.log('signalR reconnecting');
            dispatch(_mine._myActions.setConnectionState(ConnectionState.connecting));
            dispatch(_mine._myActions.setFailedError((error && error.toString()) || ''));
        });


        connection.onreconnected((connectionId) => {
            console.log('signalR reconnected');
            dispatch(_mine._myActions.setConnectionState(ConnectionState.connected));
        });


        connection.onclose(async (error) => {

            if (_mine._connectedStopped_reason) {
                dispatch(_mine._myActions.setConnectionState(ConnectionState.failed));
                dispatch(_mine._myActions.setFailedError(_mine._connectedStopped_reason));
            } else {
                console.error(`signalR disconnected ${(error || '').toString()}`);
                dispatch(_mine._myActions.setConnectionState(ConnectionState.connecting));
                dispatch(_mine._myActions.setFailedError((error || '').toString() + ' trying to reconnect'));
                _mine._intialRetrtCount = 0;
                await _mine.ensureStarted(dispatch);
            }
        });

        await _mine.ensureStarted(dispatch);

    }

    readonly _subscribedFun: {
        thunkFn: (dispatch: ThunkDispatch<ISignalRState, undefined, any>,
            getState: () => any) => Promise<any>,
        callback: (data: any) => any
    }[] = [];


    stopConnection(reason: string): ThunkResult<Promise<void>> {

        return async (dispatch, getState) => {
            console.log('signalR stopping connection :', reason);
            const _mine = this;
            await _mine._singRCreareLock.acquire('initializeSignalR', () => {
                _mine._connectedStopped_reason = reason;
                if (_mine._connection) {
                    _mine._connection.stop();
                }

                _mine._connection = undefined;
            });

            console.log('signalR connection stopped');
        }
    }


    ///this is method call from UI component to start receiving notifications
    subscribe<T>(target: string, callback: (data: T) => any): ThunkResult<Promise<void>> {
        const _mine = this;

        const thunkFn = async (dispatch: ThunkDispatch<ISignalRState, undefined, any>, getState: () => any) => {
            try {

                await _mine.ensureInit(dispatch, getState);

                console.log(`checking if callback registered :current sbscriber count : ${this._subscribedFun.length}`);

                //we havn't fixed re- susbcribing issues yet
                if (_.find(this._subscribedFun, f => f.callback == callback)) {
                    console.log('callback already registered');

                    //this causes no subscription, need to optimize this
                    //return undefined;
                } else {
                    console.log('registering callback ');
                }


                //const subscriber = new SignalRSubscriber(callback);
                //const callbackName = `messaging_${++_uniqueSubscriberId}`;
                if (_mine._connection) {
                    _mine._connection.on(target,
                        (jsonData: string) => {
                            try {
                                if (!jsonData)
                                    throw 'null callback data';

                                console.log(`got message target -> ${target}`);

                                const data = JSON.parse(jsonData) as T;

                                callback(data);

                            }
                            catch (err) {
                                console.error(`failed to handle callback :${err}`);
                            }

                        }
                    );
                    console.log(`subscribed to target -> ${target}`);
                } else {
                    console.error('connection is empty');
                }

            }
            catch (err) {
                //dee todo: We just failed to subscribe singlar is tsill on,,,, we would have different message for this
                /*
                dispatch(_mine._myActions.setConnectionState(ConnectionState.failed));
                dispatch(_mine._myActions.setFailedError((err && err.Message || 'Failed to connect')));
                */
                dispatch(_mine.stopConnection(`signalR failed to subscriber to ${target}`));

                throw err;
            };

        };

        if (!!thunkFn) {
            this._subscribedFun.push({ thunkFn, callback });
        }

        return thunkFn;
    }
}

export default () => signalRReducer.getInstance(signalRReducer, 'signalr'); 
