
/*  GENERTAED automatically using Models.tst. DO NOT CHANGE HERE */



//Generated from class neTalkServer.chatItems.MessageStatusModel
export enum MessageStatusModel  {

    sending = 'sending',
    sent = 'sent',
    sendFailed = 'sendFailed',
    received = 'received',
    receivedFailed = 'receivedFailed',
    opened = 'opened'
}

