import ReducerBase from '../bootCommon/baseReducer';
import { handleAction, handleActions, Action, ActionMeta } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { IAsyncResult, fetchJsonAsync } from '../bootCommon/asyncStateMiddleware';

import { ChatMessageModel } from '../../generated/ChatMessageModel';

import { URLBase } from '../../siteconfig';

import * as _ from 'lodash';

import ensureContacts from '../contacts/reducer';
import { MyContactModel } from '../../generated/MyContactModel';

import { MessageStatusModel } from '../../generated/MessageStatusModel';

import ensureLogin from '../login/reducer';

import AsyncLock from 'async-lock';

import { deboucedLoader } from '../utils';

let _newMessageCreateIndex = 0;

type MessageCostProps = {

    letterCount: number;
    cost: number;
}

export type MessageToSendProps = ChatMessageModel & {
    cost: MessageCostProps;
}

export interface IChatState {
    readonly allChatItemsAsync: IAsyncResult<ChatMessageModel[]>;
    readonly newMessage: MessageToSendProps;
    readonly hasMoreMessages:boolean;
};

type UpdateNewMessageProp = { key?: keyof MessageToSendProps, value?: any };

type myActions = {
    //loadItems: (value: PromiseLike<ChatMessageModel[]>) => IAsyncResult<ChatMessageModel[]>
    updateNewMessageProp: (key?: keyof MessageToSendProps, value?: any) => UpdateNewMessageProp;

    loadMessages: (value: PromiseLike<ChatMessageModel[]>, loadMore:boolean) => PromiseLike<ChatMessageModel[]>;

    ///directly updates the   allChatItemsAsync list;
    updateAMessage: (value: ChatMessageModel) => ChatMessageModel;

    setHasMore :(value:boolean) => boolean;
}

type ThunkResult<R> = ThunkAction<R, IChatState, undefined, any>;

class chatItemsReducer extends ReducerBase<IChatState, myActions>{

    createActionList() {
        return {
            updateNewMessageProp: (key?: keyof MessageToSendProps, value?: any) => ({ key, value }),


            updateAMessage: (value: ChatMessageModel) => value,

            setHasMore :(value:boolean) => value,

            loadMessages: [
                (value: PromiseLike<ChatMessageModel[]>) => value,
                (value: PromiseLike<ChatMessageModel[]>, loadMore:boolean ) => ({ Async: true, loadMore }),
            ]
        };
    }

    reducers() {


        const chatItemsHandlers: any = {};

        chatItemsHandlers[ensureLogin().setCurrentUserActionDefination()] = (
            state: IAsyncResult<ChatMessageModel[]>, action: Action<IAsyncResult<ChatMessageModel[]>>) => {
            if (!action.payload) {
                //if we just signed out remove all messages
                return null;
            }

            return state;
        };


        chatItemsHandlers[this._myActions.loadMessages.toString()] = (
            state: IAsyncResult<ChatMessageModel[]>, 
            action: ActionMeta<IAsyncResult<ChatMessageModel[]>,{loadMore:boolean}>) => {

            const newState = _.clone(action.payload);

            //we want make suer we never unload the result

            if(! (state && state.result)){
                //if there is NO existing results just return the new State
                return newState;
            }

           if (action.payload && !action.payload.result) {

                //the payload does'nt have messages...
                //load the old results are return
                newState.result = state.result;

                return newState;
            }

            if(action.meta.loadMore && action.payload && action.payload.result){
                //if we are loading more add the new message to existimg ones
                newState.result = _.uniqBy( state.result?.concat(action.payload.result), m=>m.id);
            }

            return newState;
        };


        chatItemsHandlers[this._myActions.updateAMessage.toString()] = (
            state: IAsyncResult<ChatMessageModel[]>, action: Action<ChatMessageModel>) => {

            if (!(action && action.payload)) {
                console.error('message is null');
                return state;
            }

            //if messages are NOT loaded do nothing
            if (!(state && state.result)) {
                console.error('trying to update messages b4 they are loaded');
                return state;
            }

            const newState = _.clone(state);

            newState.result = _.clone(newState.result || []);

            let foundIndex = -1;
            if (action.payload.createId) {
                foundIndex = _.findIndex(newState.result, m => m.createId == action.payload.createId);
            } else {
                action.payload.createId = (++_newMessageCreateIndex).toString();
                action.payload.status = MessageStatusModel.sending;
            }

            if (-1 == foundIndex) {
                newState.result.unshift(action.payload);
            } else {
                newState.result[foundIndex] = action.payload;
            }

            return newState;

        }



        return {

            //allChatItemsAsync: handleAction<IAsyncResult<ChatMessageModel[]>, IAsyncResult<ChatMessageModel[]>>(this._myActions.loadMessages.toString(), (state, action) => action.payload, null as any),

            allChatItemsAsync: handleActions(chatItemsHandlers, null),

            hasMoreMessages:handleAction<boolean,boolean>(this._myActions.setHasMore.toString(),(state,action)=>action.payload,false),

            newMessage: handleAction<MessageToSendProps, UpdateNewMessageProp>(this._myActions.updateNewMessageProp.toString(), (state, action) => {

                const { key, value } = action.payload;

                if (!!key) {
                    const newState = _.clone(state || {});
                    newState[key] = value;
                    return newState;
                } else {
                    //if no key we are removing the new mesage
                    return null as any;
                }

            }, null as any),
        };

    }


    readonly messageReceivedHandler = new deboucedLoader<IChatState>(async (dispatch, getState,pendingRecepiants) => {

        const { selectedContact } = ensureContacts().getCurrentState(getState());

        if (selectedContact && _.find(pendingRecepiants, r => r == selectedContact.userId)) {
            dispatch(this.loadMessages(selectedContact.userId));
        }
    });
    

    readonly messageOpenedHandler = new deboucedLoader<IChatState>(async (dispatch, getState,pendingMessagesIds) => {

        if(!pendingMessagesIds || 0 == pendingMessagesIds.length){
            return;
        }

        const counts = await fetchJsonAsync<string[]>(fetch(`${URLBase}/api/chat/ack`,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(pendingMessagesIds)
        }));
        
        
        const { allChatItemsAsync } = this.getCurrentState(getState());

        const updatedMessages = allChatItemsAsync && allChatItemsAsync.result 
                && allChatItemsAsync.result.filter(m=>pendingMessagesIds.includes(m.id)) || [];

        
        updatedMessages.forEach(m=>{
            this._myActions.updateAMessage(_.assign({},m,{status: MessageStatusModel.opened}))
        });
        
        
    });



    loadMessages(userId: string, loadMore?:boolean): ThunkResult<Promise<void>> {

        return async (dispatch, getState) => {

            if (!userId)
                throw 'no contact selected';

            const {allChatItemsAsync, hasMoreMessages} = this.getCurrentState(getState());

            if(loadMore && !hasMoreMessages){
                console.log('no more messages to load');
                return;
            }

            const loadPromise = async () => {

                const { contactsAsync, selectedContact } = ensureContacts().getCurrentState(getState());
                if (selectedContact && selectedContact.userId == userId) {
                    //all good the contact is selected
                } else {

                    let myContact = contactsAsync && contactsAsync.result
                        && _.find(contactsAsync.result, c => c.userId == userId);

                    if (!myContact) {
                        //contact not found load it
                        myContact = await fetchJsonAsync<MyContactModel>(fetch(`${URLBase}/api/contacts/single/${encodeURIComponent(userId)}`));
                        dispatch(ensureContacts().selectContact(myContact));
                    }

                }

                const needCount:number = 10

                const queryStrings :string[] = [`count=${needCount}`];
                if(loadMore){
                    const lastMessage = allChatItemsAsync && allChatItemsAsync.result 
                                                        &&  _.last(allChatItemsAsync.result);
                    
                    if(lastMessage){
                        queryStrings.push(`b4Messageid=${encodeURIComponent(lastMessage.id)}`);
                    }
                }

                //testing
                queryStrings.push();

                const messages = await fetchJsonAsync<ChatMessageModel[]>(
                    fetch(`${URLBase}/api/chat/messages/${encodeURIComponent(userId)}?` + queryStrings.join('&') ));

                dispatch(this._myActions.setHasMore(messages && messages.length >= needCount));
                
                return messages;

            };

            dispatch(this._myActions.loadMessages(loadPromise(), !!loadMore));
        };


    }

    updateNewMessage(text: string): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {
            dispatch(this._myActions.updateNewMessageProp('data', { text }));

            //dispatch(this._myActions.updateNewMessageProp('cost', null));

            //calculate the cost
            const cost: MessageCostProps = {
                letterCount: (text || '').length,
                cost: 0
            };

            const smsCount = cost.letterCount % 160;
            cost.cost = smsCount * 0.01;

            dispatch(this._myActions.updateNewMessageProp('cost', cost));
        };
    }

    sendMessage(recepientId: string): ThunkResult<Promise<void>> {
        return async (dispatch, getState) => {

            if (!recepientId) {
                console.error('null  recepientId nothing to do');
                return;
            }

            const { newMessage } = this.getCurrentState(getState());
            if (!(newMessage && newMessage.data && newMessage.data.text)) {
                console.error('message text is empty nothing to do');
                return;
            }

            const awaiter = fetchJsonAsync<ChatMessageModel>(
                fetch(`${URLBase}/api/chat/send/${encodeURIComponent(recepientId)}`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newMessage.data)
                }));

            dispatch(this._myActions.updateAMessage(newMessage));
            dispatch(this._myActions.updateNewMessageProp());

            let sent: ChatMessageModel = _.clone(newMessage);
            try {
                sent = await awaiter;
                sent.createId = newMessage.createId;
            }
            catch (ex) {
                sent.deliveryEvents = _.clone(sent.deliveryEvents || []);

                sent.deliveryEvents.unshift({
                    description: 'failed to send',
                });

            }

            dispatch(this._myActions.updateAMessage(sent));

        }

    }



}

export default () => chatItemsReducer.getInstance(chatItemsReducer, 'chatItems'); 