import React, { FunctionComponent, useContext  } from 'react';

import { MyContactModel } from '../../generated/MyContactModel';
import { StyleSheet } from 'react-native';

import { Text, View } from 'native-base';

import { Image } from 'react-native';

import {DisplayContext} from '../../navigation';

const ContactInfo: FunctionComponent<MyContactModel> = ({ displayName, userId, login }) => {

    const displayContext = useContext(DisplayContext);

    return <View nativeID={`${login}_${userId}`} style={styles.card}>
        <Image
            style={styles.userImage}
            source={require('./usericon.jpg')}
        />
        <Text style={{color:displayContext.textColor}}>{displayName}</Text>
    </View>;

}

export default ContactInfo;

const styles = StyleSheet.create({
    userImage: {
        width: 42,
        height: 42,
        resizeMode: 'stretch',
        margin: 10
    },
    card: {
        flexDirection: 'row',
        alignItems:'center',
    }
});