import React, { FunctionComponent, useEffect } from 'react';

import { ChatMessageModel } from '../../generated/ChatMessageModel';

import { Text, View, } from 'native-base';
import { StyleSheet, ActivityIndicator } from 'react-native';

import { MessageStatusModel } from '../../generated/MessageStatusModel';

import ensureChat from './reducer';

import moment from 'moment';

import * as _ from 'lodash';

import { ThunkDispatch } from 'redux-thunk';


const ChatItemView: FunctionComponent<{
    message:ChatMessageModel,
    recepientId:string,
    dispatch: ThunkDispatch<{}, {}, any>
}> =({message,recepientId,dispatch}) => {

    const { data, status, metaData } = message;

    const myMsg = message.metaData && recepientId == message.metaData.recepientId;

    useEffect(() => {
    
        const ackTimer = !myMsg && message && message.id && message.status != MessageStatusModel.opened?  
            setTimeout(()=>{
            dispatch(ensureChat().messageOpenedHandler.addToPending(message.id));
        },500):undefined;

        return ()=>{
            //if we have not acked yet then clear the timeout
            if(ackTimer){
                clearTimeout(ackTimer);
            }
            
        };

        
    }, [message]);
    


    let msgStyle:any = styles.theirMsg;
    if(myMsg){
        msgStyle = styles.myMsg;
    }else if(status != MessageStatusModel.opened){
        msgStyle = styles.theirNewMsg;
    }
    
    return <View style={myMsg?styles.myHolder:styles.theirHolder}>
        <View style={msgStyle} >
            <Text>{ data && data.text}</Text>
            <View style={styles.status} >
                {metaData && <Text style={styles.timestamp}>{metaData.extraRecepientStr} {moment(metaData.sentAt).fromNow()}</Text>}
                
                {status==MessageStatusModel.sending && <ActivityIndicator size="small" color="#00ff00" />}
            </View>
        </View>
    </View>;

}

export default ChatItemView;

const holderSmallMargin = 20;
const holderBigMargin = 60;
const msgBorderRadius = 30;

const msgBase={
    padding: 20,
    borderColor: 'rgba(204,204,204,0.24)',
    borderWidth: 2,
}


const holderBase ={
    marginHorizontal: 20,
    marginVertical: 10,
};

const styles = StyleSheet.create({
    myHolder: _.assign({},holderBase,{
        marginLeft: holderBigMargin,
        marginRight: holderSmallMargin
    }),
    myMsg: _.assign({},msgBase,{
        borderBottomLeftRadius: msgBorderRadius,
        borderTopLeftRadius: msgBorderRadius,
        backgroundColor: 'rgba(183,210,182,0.24)'
    }),

    theirHolder: _.assign({},holderBase,{
        marginLeft: holderSmallMargin,
        marginRight: holderBigMargin,
    }),

    theirMsg: _.assign({},msgBase,{
        borderBottomRightRadius: msgBorderRadius,
        borderTopRightRadius: msgBorderRadius
    }),

    theirNewMsg: _.assign({},msgBase,{
        borderBottomRightRadius: msgBorderRadius,
        borderTopRightRadius: msgBorderRadius,
        backgroundColor: 'rgba(228,159,159,0.33)'
    }),

    status:{
        flexDirection:'row',
        justifyContent:'flex-end',
    },

    timestamp:{
        fontSize:11,
        color:"#443d3d"
    }
    
});
