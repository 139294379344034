import { StatusBar } from 'expo-status-bar';

import React, { useState, useEffect } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';

import ConnectedApp from './components/bootCommon/boot-native';

import * as Font from 'expo-font';
import { Ionicons, FontAwesome } from '@expo/vector-icons';

// @ts-ignore: implicit any
import getTheme from './native-base-theme/components';

// @ts-ignore: implicit any
import theTheme from './native-base-theme/variables/platform';

import { StyleProvider } from 'native-base';

export default function App() {
    const isLoadingComplete = useCachedResources();
    const colorScheme = useColorScheme();

    const [fontLoaded, setFontLoaded] = useState(false);

    useEffect(() => {

        const loadFonts = async () => {

            await Font.loadAsync({
                Roboto: require('native-base/Fonts/Roboto.ttf'),
                Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
                ...FontAwesome.font,
                ///...Ionicons.font
            });

            /*
            await Font.loadAsync({
                ...FontAwesome.font
            });
            */

            setFontLoaded(true);
        };

        //loadFonts();
        
        
    },[]);


    if (!(isLoadingComplete /*&& fontLoaded*/)) {
        return null;
    } else {
        return <ConnectedApp>
            <StatusBar style="inverted" />

            <SafeAreaProvider>
                <StyleProvider style={getTheme(theTheme)}>
                    {/*<Navigation colorScheme={colorScheme} />*/}
                    <Navigation/>
                </StyleProvider>
            </SafeAreaProvider>

        </ConnectedApp>;
    }
}
