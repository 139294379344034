import React, { FunctionComponent } from 'react';
import Constants from 'expo-constants';
import { StyleSheet } from 'react-native';

import { DrawerNavigationProp } from '@react-navigation/drawer';

import { MainDrawerParams } from '../navigation/routeDefinations';


import { Container, Header, Title, Content, Footer, Button, Icon, Left, Body, Right, FooterTab, Text, View } from 'native-base';


//check for folder is icon fails C:\codework\newEarth.Talk\ne-talk\node_modules\react-icons\io
//https://react-icons.github.io/react-icons/icons?name=io
//https://oblador.github.io/react-native-vector-icons/


export type MyDrawerProps = {
    //navigation: ProfileScreenNavigationProp;
    TitleComponent?: React.ReactNode;

    TitleBody?:React.ReactNode;

    hideBack?:boolean;

    navigation?:{
        goBack?: ()=>void;
        toggleDrawer?:()=>void;
        canGoBack?: ()=> boolean;
    }
};




export const MyDrawerContainer: FunctionComponent<MyDrawerProps> = ({ children, 
    TitleComponent, TitleBody, navigation, hideBack  }) => {
    return <Container>
        <Header style={styles.header} >
            <Left style={styles.leftHeader}>
                {(!hideBack) && navigation && navigation.canGoBack && navigation.canGoBack() && navigation.goBack && <Button transparent
                    onPress={() => navigation.goBack && navigation.goBack()}
                >
                    <Icon type="FontAwesome" name='arrow-left' />
                </Button>
                }
                
            </Left>
            <Body>
                {/*<Title></Title>*/}
                {TitleComponent}
            </Body>
            <Right >
                {/*navigation && navigation.toggleDrawer && <Button transparent
                    onPress={() => navigation.toggleDrawer && navigation.toggleDrawer()}
                >
                    <Icon type="FontAwesome" name='navicon' />
                </Button>
                */}
            </Right>
        </Header>
        <View style={{ flex: 1, margin: 5 }} >
            {children}
        </View>
        {/*<Footer>
        <FooterTab>
            <Button full>
                <Text>Footer</Text>
            </Button>
        </FooterTab>
    </Footer>*/}
    </Container>;
}

const styles = StyleSheet.create({
    header: {
        paddingTop: Constants.statusBarHeight,
        height: 55 + Constants.statusBarHeight,
        //backgroundColor: '#f8fafc',
    },
    leftHeader:{
        flexDirection:'row',
        alignItems:'center',
    }
});